import React, {useEffect} from 'react';
import './Services.css';

import { Link } from 'react-router-dom';

import  AOS  from 'aos';
import 'aos/dist/aos.css';

import SMA from '../../img/serv-sma.jpg';
import SEO from '../../img/serv-seo.jpg';
import EM from '../../img/serv-em.jpg';
import PPC from '../../img/serv-ppc.jpg';
import LGP from '../../img/serv-lgp.jpg';
import BD from '../../img/serv-bd.jpg';
import CM from '../../img/serv-cm.jpg';
import AAM from '../../img/serv-aam.jpg';
// import YG from '../../img/serv-yg.jpg';
import GWA from '../../img/serv-gwa.jpg';
import AM from '../../img/serv-am.jpg';
import SEM from '../../img/serv-sem.jpg';
import BC from '../../img/serv-bc.jpg';
import AD from '../../img/serv-ad.jpg';
// import SP from '../../img/serv-sp.jpg';
import WS from '../../img/serv-ws.jpg';

const ServicesList = () => {
    useEffect(() => {
        AOS.init({duration: 2000});
    }, []);

  return (
    <div className="services-div">
                    <div className="services-header" data-aos="zoom-in">Explore our cutting edge services</div>
                    <div className="services-body">
                        <div className="services-cards" data-aos='fade-left'>
                            <div className="service-card sc-img" style={{backgroundImage: `url(${SMA})`}}>
                                <Link to='/services/social-media-marketing'>
                                    <div className="sc-details">
                                        <div className="sc-details-body">
                                            Social Media Marketing
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className="service-card sc-img" style={{backgroundImage: `url(${SEO})`}}>
                                <Link to='/services/search-engine-optimization'>
                                    <div className="sc-details">
                                        <div className="sc-details-body">
                                            SEO Services
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className="service-card sc-img" style={{backgroundImage: `url(${EM})`}}>
                                <Link to='/services/email-marketing'>
                                    <div className="sc-details">
                                        <div className="sc-details-body">
                                            Email Marketing
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className="service-card sc-img" style={{backgroundImage: `url(${PPC})`}}>
                                <Link to='/services/pay-per-click'>
                                    <div className="sc-details">
                                        <div className="sc-details-body">
                                            Pay Per Click
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className="service-card sc-img" style={{backgroundImage: `url(${GWA})`}}>
                                <Link to='/services/grants-writing-and-application'>
                                    <div className="sc-details">
                                        <div className="sc-details-body">
                                            Grants Writing and Application
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className="service-card sc-img" style={{backgroundImage: `url(${BC})`}}>
                                <Link to='/services/business-consulting'>
                                    <div className="sc-details">
                                        <div className="sc-details-body">
                                            Business Consulting
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className="service-card sc-img" style={{backgroundImage: `url(${LGP})`}}>
                                <Link to='/services/lead-generation'>
                                    <div className="sc-details">
                                        <div className="sc-details-body">
                                            Lead Generation Projects
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className="service-card sc-img" style={{backgroundImage: `url(${BD})`}}>
                                <Link to='/services/brand-development'>
                                    <div className="sc-details">
                                        <div className="sc-details-body">
                                            Brand Development
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className="service-card sc-img" style={{backgroundImage: `url(${CM})`}}>
                                <Link to='/services/content-marketing'>
                                    <div className="sc-details">
                                        <div className="sc-details-body">
                                            Content Marketing
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className="service-card sc-img" style={{backgroundImage: `url(${AAM})`}}>
                                <Link to='/services/amazon-ads-management'>
                                    <div className="sc-details">
                                        <div className="sc-details-body">
                                            Amazon Ads Management
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className="service-card sc-img" style={{backgroundImage: `url(${SEM})`}}>
                                <Link to='/services/search-engine-marketing'>
                                    <div className="sc-details">
                                        <div className="sc-details-body">
                                            Search Engine Marketing
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className="service-card sc-img" style={{backgroundImage: `url(${AD})`}}>
                                <Link to='/services/ad-creatives-and-designs'>
                                    <div className="sc-details">
                                        <div className="sc-details-body">
                                            Ad Creatives and Designs
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className="service-card sc-img" style={{backgroundImage: `url(${AM})`}}>
                                <Link to='/services/affiliate-marketing'>
                                    <div className="sc-details">
                                        <div className="sc-details-body">
                                            Affliate Marketing
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            {/* <div className="service-card sc-img" style={{backgroundImage: `url(${SP})`}}>
                                <Link to='/services/spotify-promotion'>
                                    <div className="sc-details">
                                        <div className="sc-details-body">
                                            Spotify Promotion
                                        </div>
                                    </div>
                                </Link>
                            </div> */}
                            <div className="service-card sc-img" style={{backgroundImage: `url(${WS})`}}>
                                <Link to='/services/web-services'>
                                    <div className="sc-details">
                                        <div className="sc-details-body">
                                            Full-Stack Web Services
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
  )
}

export default ServicesList