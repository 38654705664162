import React, { useEffect } from 'react';
import './Services.css';
import AOS from 'aos';
import 'aos/dist/aos.css';

import CM from '../../img/serv-cm.jpg';

import './ServicesTop/ServicesTop.css';
import Blog from '../Blog/Blog';
import ServicesList from './ServicesList';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

const Cm = () => {
    useEffect(() => {
        AOS.init({ duration: 2000 });
    }, []);

    return (
        <>
            <Helmet>
                <meta name="fragment" content="!" />
                <title>
                    Content Marketing - MediaFuse Agency
                </title>
                <meta
                    name="description"
                    content="Deliver top quality content that resonates with your target audience, stimulates their interest, 
                    and influences their purchasing decisions"
                />
                <link rel="canonical" href="https://mediafuseagency.com/content-marketing" />
                <meta property="og:title" content="Content Marketing - MediaFuse Agency" />
                <meta
                    property="og:description"
                    content="Deliver top quality content that resonates with your target audience, stimulates their interest, 
                    and influences their purchasing decisions"
                />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://mediafuseagency.com/content-marketing" />
                <meta property="og:site_name" content="MediaFuse Agency" />

                <meta property="og:image" content="https://mediafuseagency.com/static/media/MFP.d57d3e5c53694bbedd86.jpg" />
                <meta property="og:image:secure_url" content="https://mediafuseagency.com/static/media/MFP.d57d3e5c53694bbedd86.jpg" />
                <meta property="og:image:type" content="image/jpg" />
                <meta property="og:image:width" content="1200" />
                <meta property="og:image:height" content="630" />
                <meta property="fb:app_id" content="654497786394804" />

                <meta name="twitter:title" content="Content Marketing - MediaFuse Agency" />
                <meta
                    name="twitter:description"
                    content="Deliver top quality content that resonates with your target audience, stimulates their interest, 
                    and influences their purchasing decisions"
                />
                <meta name="twitter:image" content="https://mediafuseagency.com/static/media/MFP.d57d3e5c53694bbedd86.jpg" />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>
            <div>
                <div className="services">
                    <div className="services-con">
                        <div>
                            <div className="services-top">
                                <div className="services-top-con b-fancy">
                                    <div className="services-top-con-div">
                                        <div className="stcd serv-div">
                                            <div className="stcd-right serv">
                                                <h1 className="serv-header">
                                                    Reinforce your marketing with top quality content
                                                </h1>
                                                <p className='serv-body'>
                                                    Deliver top quality content that resonates with your target audience, stimulates their interest,
                                                    and influences their purchasing decisions with our strategy, that includes researching keywords, developing content, and creating stunning designs that drive conversions.

                                                </p>

                                                <Link to='/contact/sales'>
                                                    <div className="gl-click-sm button-w">
                                                        <span className='gl-click-txt-sm'>Let's get started</span>
                                                        <span class="material-symbols-outlined in-left-arrow">
                                                            arrow_right_alt
                                                        </span>
                                                    </div>
                                                </Link>
                                            </div>
                                            <div className="stcd-left">
                                                <img className="stcd-img no-b" src={CM} alt='MediaFuse Agency Services' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ServicesList />
                        <Blog />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Cm