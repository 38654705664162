import React, { useEffect } from 'react';
import './Services.css';
import '../Intro/Intro.css'
import { Link } from 'react-router-dom';

import AOS from 'aos';
import 'aos/dist/aos.css';

import SMA from '../../img/serv-sma.jpg';
import SEO from '../../img/serv-seo.jpg';
import LGP from '../../img/serv-lgp.jpg';
import SEM from '../../img/serv-sem.jpg';
import BC from '../../img/serv-bc.jpg';
import AD from '../../img/serv-ad.jpg';

const Service = () => {
    useEffect(() => {
        AOS.init({ duration: 2000 });
    }, []);



    return (
        <div>
            <div className="services">
                <div className="services-con">
                    <div className="services-div">
                        <div className="services-header" data-aos="zoom-in">Explore our cutting edge services</div>
                        <div className="services-body">
                            <div className="services-cards" data-aos='fade-left'>
                                <div className="service-card sc-img" style={{ backgroundImage: `url(${SMA})` }}>
                                    <Link to="/services/social-media-marketing">
                                        <div className="sc-details">
                                            <div className="sc-details-body">
                                                Social Media Marketing
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                                <div className="service-card sc-img" style={{ backgroundImage: `url(${SEO})` }}>
                                    <Link to="/services/search-engine-optimization">
                                        <div className="sc-details">
                                            <div className="sc-details-body">
                                                SEO Services
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                                <div className="service-card sc-img" style={{ backgroundImage: `url(${BC})` }}>
                                    <Link to="/services/business-consulting">
                                        <div className="sc-details">
                                            <div className="sc-details-body">
                                                Business Consulting
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                                <div className="service-card sc-img" style={{ backgroundImage: `url(${LGP})` }}>
                                    <Link to="/services/lead-generation">
                                        <div className="sc-details">
                                            <div className="sc-details-body">
                                                Lead Generation Projects
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                                <div className="service-card sc-img" style={{ backgroundImage: `url(${SEM})` }}>
                                    <Link to="/services/search-engine-marketing">
                                        <div className="sc-details">
                                            <div className="sc-details-body">
                                                Search Engine Marketing
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                                <div className="service-card sc-img" style={{ backgroundImage: `url(${AD})` }}>
                                    <Link to="/services/ad-creatives-and-designs">
                                        <div className="sc-details">
                                            <div className="sc-details-body">
                                                Ad Creatives and Designs
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                            <div className="service-btn">
                                <Link to='/services'>
                                    <div className="gl-click button-b">
                                        <span className='gl-click-txt'>Discover more Services</span>
                                        <span class="material-symbols-outlined in-left-arrow">
                                            arrow_right_alt
                                        </span>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Service