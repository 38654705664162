import React, { useEffect } from 'react';
import './Services.css';

import  AOS  from 'aos';
import 'aos/dist/aos.css';

import ServicesTop from './ServicesTop/ServicesTop';
import Blog from '../Blog/Blog';
import ServicesList from './ServicesList';
import { Helmet } from 'react-helmet-async';

const Services = () => {
    useEffect(() => {
        AOS.init({duration: 2000});
    }, []);

  return (
    <>
    <Helmet>
    <meta name="fragment" content="!" />
    <title>
        Services - MediaFuse Agency
      </title>
      <meta
        name="description"
        content="Supercharge your brand's marketing strategies with high yield digital marketing solutions"
      />
      <link rel="canonical" href="https://mediafuseagency.com/about" />
      <meta property="og:title" content="Services - MediaFuse Agency" />
      <meta 
          property="og:description"
          content="Supercharge your brand's marketing strategies with high yield digital marketing solutions"
      />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://mediafuseagency.com/about" />
      <meta property="og:site_name" content="MediaFuse Agency" />

      <meta property="og:image" content="https://mediafuseagency.com/static/media/MFP.d57d3e5c53694bbedd86.jpg" />
      <meta property="og:image:secure_url" content="https://mediafuseagency.com/static/media/MFP.d57d3e5c53694bbedd86.jpg" />
      <meta property="og:image:type" content="image/jpg" />
      <meta property="og:image:width" content="1200"/>
      <meta property="og:image:height" content="630"/>
      <meta property="fb:app_id" content="654497786394804" />

      <meta name="twitter:title" content="Services - MediaFuse Agency" />
      <meta 
          name="twitter:description" 
          content="Supercharge your brand's marketing strategies with high yield digital marketing solutions"
      />
      <meta name="twitter:image" content="https://mediafuseagency.com/static/media/MFP.d57d3e5c53694bbedd86.jpg" />
      <meta name="twitter:card" content="summary_large_image" />
    </Helmet>
    <div>
        <div className="services">
            <div className="services-con">
                <ServicesTop />
                <ServicesList />
                <Blog />
            </div>
        </div>
    </div>
    </>
  )
}

export default Services