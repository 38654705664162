import React, { useEffect, useState, useRef } from 'react';
import './Sales.css';
import { BsPersonPlus } from 'react-icons/bs';
import { IoMailOutline } from 'react-icons/io5';
import { BsCheckCircleFill } from 'react-icons/bs';
import PhoneInput from 'react-phone-number-input';
import emailjs from '@emailjs/browser';
import 'react-phone-number-input/style.css';
import { Helmet } from 'react-helmet-async';

const Sales = () => {
    const [check1, setCheck1] = useState('#ccc');
    const [check2, setCheck2] = useState('#ccc');
    const [check3, setCheck3] = useState('#ccc');
    const [check4, setCheck4] = useState('#ccc');
    const [textColor1, setTextColor1] = useState('#ccc');
    const [textColor2, setTextColor2] = useState('#ccc');
    const [textColor3, setTextColor3] = useState('#ccc');
    const [textColor4, setTextColor4] = useState('#ccc');

    const [namess, setNamess] = useState('');
    const [emails, setEmails] = useState('');
    const [whtsp, setWhtsp] = useState('');
    const [desc, setDesc] = useState('');

    const [fmc1, setFmc1] = useState('100%');
    const [fmc2, setFmc2] = useState('0');
    const [fmc3, setFmc3] = useState('0');
    const [fmc4, setFmc4] = useState('0');

    const [isChecked, setIsChecked] = useState([]);


    useEffect(() => {
        console.log(namess)
        console.log(emails)
    }, [namess, emails])



    // EmailJS

    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_1rzzy7l', 'template_llyj1rl', form.current, '8ns9UEo-6LJHtdSiy')
            .then((result) => {
                console.log(result.text);
                window.alert('Your  message has been sent successfully. We will respond via your email shortly.');
                window.location.reload();
            }, (error) => {
                console.log(error.text);
                window.alert(error.text)
            });
    };

    // 



    const service = () => {
        return (
            isChecked.map((s, i) => (
                <span key={i}>{s}, </span>
            ))
        )
    }

    const handleNamess = e => {
        setNamess(e.target.value)
    }

    const isValidEmail = em => {
        return /\S+@\S+\.\S+/.test(em);
    }

    const handleEmails = e => {
        if (isValidEmail(e.target.value)) {
            setEmails(e.target.value)
        } else {
            setEmails('')
        }
    }

    const handleDesc = e => {
        setDesc(e.target.value)
    }

    const next = () => {
        if (namess !== '' && emails !== '') {
            if (fmc1 === '100%') {
                setFmc1('0')
                setFmc2('100%')
                setCheck1('#67e640')
                setTextColor1('#fff')
            } else if (fmc2 === '100%') {
                setFmc2('0')
                setFmc1('100%')
                setCheck1('#67e640')
                setTextColor1('#fff')
            }
        } else {
            // console.log('name cannot be empty and email must be valid')
            window.alert('Kindly ensure your name is provided and your email is entered correctly')
        }
    }

    const prev2 = () => {
        setFmc2('0')
        setFmc1('100%')
    }

    const next2 = () => {
        if (isChecked.length !== 0) {
            setFmc2('0')
            setFmc3('100%')
            setCheck2('#67e640')
            setTextColor2('#fff')
        } else {
            window.alert('Please select a service to continue')
        }
    }

    const prev3 = () => {
        setFmc3('0')
        setFmc2('100%')
    }

    const next3 = () => {
        if (desc !== '') {
            setFmc3('0')
            setFmc4('100%')
            setCheck3('#67e640')
            setTextColor3('#fff')
        } else {
            window.alert('Kindly tell us a little more about what you want')
        }
    }

    const prev4 = () => {
        setFmc4('0')
        setFmc3('100%')
        setCheck4('#67e640')
        setTextColor4('#fff')
    }

    const handleToggle = e => {
        const clickedService = e.target.value;
        const all = [...isChecked];
        const serviceIndex = isChecked.indexOf(clickedService)

        if (e.target.checked) {
            all.push(clickedService)
            console.log(all);
        } else {
            all.splice(serviceIndex, 1)
            console.log(all)
        }
        setIsChecked(all);
    };

    return (
        <>
            <Helmet>
                <meta name="fragment" content="!" />
                <title>
                    Let's Get Started - MediaFuse Agency
                </title>
                <meta
                    name="description"
                    content="We're eager to learn your latest digital marketing project and how we can help. Reach out to us."
                />
                <link rel="canonical" href="https://mediafuseagency.com/contact/sales" />
                <meta property="og:title" content="Let's Get Started - MediaFuse Agency" />
                <meta
                    property="og:description"
                    content="We're eager to learn your latest digital marketing project and how we can help. Reach out to us."
                />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://mediafuseagency.com/contact/sales" />
                <meta property="og:site_name" content="MediaFuse Agency" />

                <meta property="og:image" content="https://mediafuseagency.com/static/media/MFP.d57d3e5c53694bbedd86.jpg" />
                <meta property="og:image:secure_url" content="https://mediafuseagency.com/static/media/MFP.d57d3e5c53694bbedd86.jpg" />
                <meta property="og:image:type" content="image/jpg" />
                <meta property="og:image:width" content="1200" />
                <meta property="og:image:height" content="630" />
                <meta property="fb:app_id" content="654497786394804" />

                <meta name="twitter:title" content="Let's Get Started - MediaFuse Agency" />
                <meta
                    name="twitter:description"
                    content="We're eager to learn your latest digital marketing project and how we can help. Reach out to us."
                />
                <meta name="twitter:image" content="https://mediafuseagency.com/static/media/MFP.d57d3e5c53694bbedd86.jpg" />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>
            <div>
                <div className="sales">
                    <div className="sales-main">
                        <div className="sales-con">
                            <div className="sales-con-div">
                                <div className="scon">
                                    <div className="scon-left">
                                        <div className="scon-top">
                                            <div className="sct-header">Okay, let's get started</div>
                                            <div className="sct-body">
                                                Fill the form with the appropriate information and we'll get back
                                                to you in 24 hours.
                                            </div>
                                        </div>
                                        <div className="scon-2">
                                            <div className="scon-steps">
                                                <div style={{ color: check1 }}><BsCheckCircleFill /></div>
                                                <label style={{ color: textColor1 }} className="scon-steps-mob">Details</label>
                                                <label style={{ color: textColor1 }}>Let's have your details</label>
                                            </div>
                                            <div className="scon-steps">
                                                <div style={{ color: check2 }}><BsCheckCircleFill /></div>
                                                <label style={{ color: textColor2 }} className="scon-steps-mob">Services</label>
                                                <label style={{ color: textColor2 }}>Select Services</label>
                                            </div>
                                            <div className="scon-steps">
                                                <div style={{ color: check3 }}><BsCheckCircleFill /></div>
                                                <label style={{ color: textColor3 }} className="scon-steps-mob">Description</label>
                                                <label style={{ color: textColor3 }}>Add some description</label>
                                            </div>
                                            <div className="scon-steps">
                                                <div style={{ color: check4 }}><BsCheckCircleFill /></div>
                                                <label style={{ color: textColor4 }} className="scon-steps-mob">Done</label>
                                                <label style={{ color: textColor4 }}>You're good to go</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="scon-right">
                                        <div className="scon-form-div">
                                            <form ref={form} onSubmit={sendEmail} className="sc-form">
                                                <div className="fm-card" style={{ width: fmc1 }}>
                                                    <div className="fields">
                                                        <label htmlFor="names" className='label'>Your Name</label>
                                                        <div className="input">
                                                            <BsPersonPlus />
                                                            <input onChange={handleNamess} type="text" name="names" required />
                                                        </div>
                                                    </div>
                                                    <div className="fields">
                                                        <label htmlFor="email" className='label'>Email Address</label>
                                                        <div className="input">
                                                            <IoMailOutline />
                                                            <input onChange={handleEmails} type="email" name="email" required />
                                                        </div>
                                                    </div>
                                                    <div className="fields">
                                                        <label htmlFor="whtsp" className='label'>WhatsApp <em>(optional)</em></label>
                                                        <div className="input">
                                                            <PhoneInput
                                                                placeholder='Select country or add code'
                                                                value={whtsp}
                                                                onChange={setWhtsp}
                                                            />
                                                            <input type="text" className="h-input" name='whtsp' value={whtsp} />
                                                        </div>
                                                    </div>
                                                    <div className="scon-btn">
                                                        <label className="sc-next" onClick={next}>Next</label>
                                                    </div>
                                                </div>
                                                <div className="fm-card" style={{ width: fmc2 }}>
                                                    <div className="fields">
                                                        <label htmlFor="services" className='label'>What service(s) would you like?</label>
                                                        <input type='text' className="h-input" name="services" value={isChecked.join(", ")} />
                                                        <div className="input-chck">
                                                            <label>
                                                                <input onChange={handleToggle} type="checkbox" name="ad" value='Ad Creatives and Designs' />
                                                                <span>Ad Creatives and Designs </span>
                                                            </label>
                                                            <label>
                                                                <input onChange={handleToggle} type="checkbox" name="am" value='Affiliate Marketing' />
                                                                <span>Affiliate Marketing </span>
                                                            </label>
                                                            <label>
                                                                <input onChange={handleToggle} type="checkbox" name="aam" value='Amazon Ads Management' />
                                                                <span>Amazon Ads Management </span>
                                                            </label>
                                                            <label>
                                                                <input onChange={handleToggle} type="checkbox" name="bd" value='Brand Development' />
                                                                <span>Brand Development </span>
                                                            </label>
                                                            <label>
                                                                <input onChange={handleToggle} type="checkbox" name="bc" value='Buisness Consulting' />
                                                                <span>Buisness Consulting </span>
                                                            </label>
                                                            <label>
                                                                <input onChange={handleToggle} type="checkbox" name="cm" value='Content Marketing' />
                                                                <span>Content Marketing </span>
                                                            </label>
                                                            <label>
                                                                <input onChange={handleToggle} type="checkbox" name="em" value='Email Marketing' />
                                                                <span>Email Marketing </span>
                                                            </label>
                                                            <label>
                                                                <input onChange={handleToggle} type="checkbox" name="ws" value='Full-Stack Web Services' />
                                                                <span>Full-Stack Web Services </span>
                                                            </label>
                                                            <label>
                                                                <input onChange={handleToggle} type="checkbox" name="lgp" value='Lead Development Projects' />
                                                                <span>Lead Development Projects </span>
                                                            </label>
                                                            <label>
                                                                <input onChange={handleToggle} type="checkbox" name="ppc" value='Pay Per Click' />
                                                                <span>Pay Per Click </span>
                                                            </label>
                                                            <label>
                                                                <input onChange={handleToggle} type="checkbox" name="sem" value='Search Engine Marketing' />
                                                                <span>Search Engine Marketing </span>
                                                            </label>
                                                            <label>
                                                                <input onChange={handleToggle} type="checkbox" name="seo" value='SEO Services' />
                                                                <span>SEO Services </span>
                                                            </label>
                                                            <label>
                                                                <input onChange={handleToggle} type="checkbox" name="smm" value='Social Media Marketing' />
                                                                <span>Social Media Marketing </span>
                                                            </label>
                                                            {/* <label>
                                                                <input onChange={handleToggle} type="checkbox" name="sp" value='Spotify Promotion' />
                                                                <span>Spotify Promotion </span>
                                                            </label> */}
                                                            <label>
                                                                <input onChange={handleToggle} type="checkbox" name="gwa" value='Grant Writing and Application' />
                                                                <span>Grant Writing and Application </span>
                                                            </label>

                                                        </div>
                                                    </div>

                                                    <div className="scon-btn2">
                                                        <label className="sc-prev" onClick={prev2}>Prev</label>
                                                        <label className="sc-next" onClick={next2}>Next</label>
                                                    </div>
                                                </div>

                                                <div className="fm-card" style={{ width: fmc3 }}>
                                                    <div className="fields">
                                                        <label htmlFor="description" className='label'>Tell us more about what you want</label>
                                                        <textarea className="input" rows='7' onChange={handleDesc} type="text" name="description" required />
                                                    </div>
                                                    <div className="scon-btn2">
                                                        <label className="sc-prev" onClick={prev3}>Prev</label>
                                                        <label className="sc-next" onClick={next3}>Next</label>
                                                    </div>
                                                </div>

                                                <div className="fm-card" style={{ width: fmc4 }}>
                                                    <div className="fields">
                                                        <label className='label'>Just confirm all the details and you're good to go</label>
                                                        <div className='cn'>
                                                            <div className="cn-name">
                                                                <label>Name:</label>
                                                                <span>{namess}</span>
                                                            </div>
                                                            <div className="cn-name">
                                                                <label>Email:</label>
                                                                <span>{emails}</span>
                                                            </div>
                                                            <div className="cn-name">
                                                                <label>WhatsApp:</label>
                                                                <span>{whtsp}</span>
                                                            </div>
                                                            <div className="cn-name">
                                                                <label>Services:</label>
                                                                <span>{service()}</span>
                                                            </div>
                                                            <div className="cn-name">
                                                                <label>Description:</label>
                                                                <span>{desc}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="scon-btn2">
                                                        <label className="sc-prev" onClick={prev4}>Prev</label>
                                                        <input type="submit" className="sc-submit" onClick={next3} value="Submit" />
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Sales