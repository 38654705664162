import React, { useState } from 'react';
import './Navbar.css';

import { BsTwitterX } from "react-icons/bs";
import { SiInstagram } from "react-icons/si";
import { FaFacebookF } from "react-icons/fa";
import { BsWhatsapp } from "react-icons/bs";
import { FaLinkedinIn } from "react-icons/fa6";
import { FaYoutube } from "react-icons/fa6";

import Logo from '../../img/MFLogo.png';
import LogoSm from '../../img/logo-sm.png';
import Prv from '../../img/MFP.jpg';
import { Link, Outlet, ScrollRestoration, } from 'react-router-dom';
import Footer from '../Footer/Footer';

const Navbar = () => {
    const [show, setShow] = useState('visible');
    const [showa, setShowa] = useState('hidden');
    const [showb, setShowb] = useState('0');
    const [show1, setShow1] = useState('none');
    const [show1a, setShow1a] = useState('block');
    const [show1b, setShow1b] = useState('0');
    const [show2, setShow2] = useState('none');
    const [show2a, setShow2a] = useState('block');
    const [show2b, setShow2b] = useState('0');

    const toggle = () => {
        if (show === 'hidden') {
            setShow('visible')
            setShowa('hidden')
            setShowb('0')
        } else {
            setShow('hidden')
            setShowa('visible')
            setShowb('100%')
        }
    }

    const toggle1 = () => {
        if (show1 === 'none') {
            setShow1('block')
            setShow1a('none')
            setShow1b('483px')
        } else {
            setShow1('none')
            setShow1a('block')
            setShow1b('0')
        }
    }

    const toggle2 = () => {
        if (show2 === 'none') {
            setShow2('block')
            setShow2a('none')
            setShow2b('138px')
        } else {
            setShow2('none')
            setShow2a('block')
            setShow2b('0')
        }
    }

    return (
        <div className='body'>
            <div className="nav">
                <div className="mobile" style={{ width: showb }}>
                    <div className="mob-close">
                        <div className="logo-sm">
                            <Link to='/'><img src={LogoSm} alt="MediaFuse Agency" className="logo-sm-img" style={{ visibility: showa }} /></Link>
                        </div>
                        <span onClick={toggle} class="material-symbols-outlined" style={{ visibility: showa }}>
                            close
                        </span>
                    </div>
                    <img src={Prv} alt="" style={{ display: 'none' }} />
                    <div className="mob-menu">
                        <ul>
                            <li>
                                Our Services
                                <span onClick={toggle1} class="material-symbols-outlined" style={{ display: show1a }}>
                                    expand_more
                                </span>
                                <span onClick={toggle1} class="material-symbols-outlined" style={{ display: show1 }}>
                                    expand_less
                                </span>
                            </li>
                            <ul className='sub' style={{ height: show1b }}>
                                <li onClick={toggle}><Link to='/services/social-media-marketing'>Social Media Marketing</Link></li>
                                <li onClick={toggle}><Link to='/services/search-engine-optimization'>SEO Services</Link></li>
                                <li onClick={toggle}><Link to='/services/pay-per-click'>Pay per click Advertising</Link></li>
                                <li onClick={toggle}><Link to='/services/email-marketing'>email marketing</Link></li>
                                <li onClick={toggle}><Link to='/services/lead-generation'>lead generation projects</Link></li>
                                <li onClick={toggle}><Link to='/services/brand-development'>brand development</Link></li>
                                <li onClick={toggle}><Link to='/services/content-marketing'>content marketing</Link></li>
                                <li onClick={toggle}><Link to='/services/amazon-ads-management'>amazon ads management</Link></li>
                                <li onClick={toggle}><Link to='/services/affiliate-marketing'>Affiliate marketing</Link></li>
                                <li onClick={toggle}><Link to='/services/search-engine-marketing'>Search engine marketing</Link></li>
                                <li onClick={toggle}><Link to='/services/business-consulting'>Business consulting</Link></li>
                                <li onClick={toggle}><Link to='/services/ad-creatives-and-designs'>Ad creatives and designs</Link></li>
                                <li onClick={toggle}><Link to='/services/grants-writing-and-application'>Grants writing and application</Link></li>
                                <li onClick={toggle}><Link to='/services/web-services'>Web development services</Link></li>
                            </ul>
                            <li onClick={toggle}><a href='/#reviews' className='bold'>Our Reviews</a></li>
                            <li>
                                Our Policies
                                <span onClick={toggle2} class="material-symbols-outlined" style={{ display: show2a }}>
                                    expand_more
                                </span>
                                <span onClick={toggle2} class="material-symbols-outlined" style={{ display: show2 }}>
                                    expand_less
                                </span>
                            </li>
                            <ul className='sub' style={{ height: show2b }}>
                                <li onClick={toggle}><Link to='/privacy'>Privacy Policy</Link></li>
                                <li onClick={toggle}><Link to='/terms'>Terms of Use</Link></li>
                            </ul>
                            <li onClick={toggle} ><Link to='/about'><label className='bold'>About Us</label></Link></li>
                            <li onClick={toggle} ><a href='http://blog.mediafuseagency.com'><label className='bold'>Blog</label></a></li>
                        </ul>
                        <div className="mob-socials">
                            <ul>
                                <a href='http://www.facebook.com/mediafuseagency'><li><FaFacebookF /></li></a>
                                <a href='https://twitter.com/mediafuseagency'><li><BsTwitterX /></li></a>
                                <a href='https://instagram.com/mediafuseagency?igshid=YmMyMTA2M2Y='><li><SiInstagram /></li></a>
                                <a href='https://www.linkedin.com/company/mediafuse-agency/'><li><FaLinkedinIn /></li></a>
                                <a href='https://wa.me/message/YMN27FIOKTKDB1'><li><BsWhatsapp /></li></a>
                                <a href='https://www.youtube.com/@MediaFuseAgency'><li><FaYoutube /></li></a>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="n-wrapper">
                    <div className="n-left">
                        <div className="menu-bar">
                            <span onClick={toggle} class="material-symbols-outlined" style={{ visibility: show }}>
                                menu
                            </span>
                        </div>
                        <div className="n-name"><Link to='/'><img src={Logo} alt='media fuse agency' /></Link></div>
                    </div>
                    <div className="n-right">
                        <div className="n-list">
                            <ul>
                                <li><a href='http://blog.mediafuseagency.com'>Blog</a></li>
                                <li><Link to='/services'>Services</Link></li>
                                <li><a href="/#reviews">Reviews</a></li>
                                <li><Link to='/about'>About</Link></li>
                                <li><Link to='/privacy'>Privacy</Link></li>
                            </ul>
                        </div>
                        <Link to='/contact/sales'>
                            <button className="button n-button">
                                <span>Contact us</span>
                                <i class="fa fa-phone" aria-hidden="true"></i>
                            </button>
                        </Link>
                    </div>
                </div>
            </div>

            <main>
                <Outlet />
            </main>

            <Footer />

            <ScrollRestoration
                getKey={(location) => {
                    return location.pathname;
                }}
            />
        </div>
    )
}

export default Navbar