import React from 'react';
import './Privacy.css'
import Logo from '../../img/MFLogo.png'; 
import { Helmet } from 'react-helmet-async';

const AboutUs = () => {
  return (
    <>
    <Helmet>
    <meta name="fragment" content="!" />
    <title>
        About Us - MediaFuse Agency
      </title>
      <meta
        name="description"
        content="MediaFuse is a crew of professionals who are dedicated to helping businesses grow and succeed in the digital landscape"
      />
      <link rel="canonical" href="https://mediafuseagency.com/about" />
      <meta property="og:title" content="About Us - MediaFuse Agency" />
      <meta 
          property="og:description"
          content="MediaFuse is a crew of professionals who are dedicated to helping businesses grow and succeed in the digital landscape"
      />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://mediafuseagency.com/about" />
      <meta property="og:site_name" content="MediaFuse Agency" />

      <meta property="og:image" content="https://mediafuseagency.com/static/media/MFP.d57d3e5c53694bbedd86.jpg" />
      <meta property="og:image:secure_url" content="https://mediafuseagency.com/static/media/MFP.d57d3e5c53694bbedd86.jpg" />
      <meta property="og:image:type" content="image/jpg" />
      <meta property="og:image:width" content="1200"/>
      <meta property="og:image:height" content="630"/>
      <meta property="fb:app_id" content="654497786394804" />

      <meta name="twitter:title" content="About Us - MediaFuse Agency" />
      <meta 
          name="twitter:description" 
          content="MediaFuse is a crew of professionals who are dedicated to helping businesses grow and succeed in the digital landscape"
      />
      <meta name="twitter:image" content="https://mediafuseagency.com/static/media/MFP.d57d3e5c53694bbedd86.jpg" />
      <meta name="twitter:card" content="summary_large_image" />
    </Helmet>
    <div>
        <div className="privacy">
            <div className="top-img">
                <img src={Logo} alt="Mediafuse agency About Us" />
            </div>
            <div className="about-us-con">
                <div className="privacy-con-div">
                    <div className="pcd">
                        <div className="pcd-header">
                            About MediaFuse Agency
                        </div>
                        <div className="pcd-body">
                            
                            <p className="eff-date">
                            MediaFuse is a crew of professionals who are dedicated to helping businesses grow and succeed in the digital landscape. Our agency specializes in providing a range of digital marketing services.
At our agency, we understand that every business is unique and has its own set of goals and challenges. Therefore, we take a customized approach to every project we undertake. Our team works closely with clients to understand their specific needs and develop tailored strategies that deliver results.
We believe that digital marketing is all about connecting with your target audience in a meaningful way. That's why we focus on creating engaging and relevant content that resonates with your audience and drives them to take action. Our team combines creativity with data-driven insights to deliver effective digital campaigns that deliver real-world results.
Our agency is committed to delivering exceptional customer service and support. We pride ourselves on our transparency and communication, and we work closely with clients to ensure that they are always informed and involved in the digital marketing process.
                            </p>
                        </div>
                        <div className="pcd-header">
                            Why work with us?
                        </div>
                        <div className="pcd-body">
                            
                            <p className="eff-date">
                            Our agency is founded on the belief that digital marketing is essential for businesses to succeed in today's competitive market. With the ever-evolving digital landscape, it can be challenging for businesses to keep up with the latest trends and techniques. That's where we come in. Our team of experts stays up-to-date with the latest industry trends and best practices to ensure that our clients receive the best possible digital marketing services.
We take a data-driven approach to our digital marketing strategies, leveraging insights and analytics to optimize campaigns and drive results. Our team is equipped with the latest tools and technologies to track and measure campaign performance, enabling us to make data-driven decisions that help our clients achieve their goals.
At our agency, we prioritize collaboration and teamwork. We believe that the best results come from a collective effort, which is why we work closely with our clients to understand their business goals, values, and objectives. Our team collaborates with clients every step of the way, from strategy development to campaign execution and optimization.
We are proud to have worked with a diverse range of clients, from small startups to large corporations. Our team has a proven track record of delivering successful digital marketing campaigns that drive business growth and success.
In conclusion, our agency is committed to delivering exceptional digital marketing services that help businesses thrive in the digital age. Contact us today to learn more about how we can help your business grow and succeed online
                            </p>
                        </div>
                    </div>
                </div>
                <div className="a-socials">
                    <div className="a-socials-h">Follow Our Socials</div>
                    <div className="a-socials-b">
                        <ul>
                            <a href='http://www.facebook.com/mediafuseagency'><li><i class="fa fa-facebook" aria-hidden="true"></i></li></a>
                            <a href='https://twitter.com/mediafuseagency'><li><i class="fa fa-twitter" aria-hidden="true"></i></li></a>
                            <a href='https://instagram.com/mediafuseagency?igshid=YmMyMTA2M2Y='><li><i class="fa fa-instagram" aria-hidden="true"></i></li></a>
                            <a href='https://www.linkedin.com/company/mediafuse-agency/'><li><i class="fa fa-linkedin" aria-hidden="true"></i></li></a>
                            <a href='https://wa.me/message/YMN27FIOKTKDB1'><li><i class="fa fa-whatsapp" aria-hidden="true"></i></li></a>
                        </ul>
                    </div>
                </div>  
            </div>
            
        </div>
    </div>
    </>
  )
}

export default AboutUs