import React, { useEffect, useState } from 'react';
import './Blog.css';


import { list } from '../../actions/blogs';
import htmr from 'htmr';
import moment from 'moment';

const Blog = () => {
    const [blogs, setBlogs] = useState([])

    useEffect(() => {
        listblogs()
    }, [])

    const listblogs = () => {
        list().then(data => {
            if (data.error) {
                console.log(data.error)
            }
            else {
                setBlogs(data)
            }
        })
    }

    const blogList = () => {
        return blogs.slice(0, 4).map((blog, i) => {
            return (
                <a href={`https://blog.mediafuseagency.com/blogs/${blog.slug}`}>
                    <div className="bcd-card" key={i}>
                        <div className="bcd-img-div bcd-img" style={{ backgroundImage: `url(https://blog.mediafuseagency.com/api/blog/photo/${blog.slug})` }}></div>
                        <div className="bcd-details">
                            <div className="bcd-details-body">
                                <div className="bcddb-header">
                                    {blog.title}
                                </div>
                                <div className="bcddb-body">
                                    {htmr(blog.excerpt)}
                                </div>
                                <div className="bcddb-stats">
                                    <div className="date">{moment(blog.createdAt).format('MMM DD, YYYY')}</div>
                                    <div className="views">
                                        <span class="material-symbols-outlined">
                                            visibility
                                        </span>
                                        <div className="txt">{blog.edpicks}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </a>
            )
        })
    }


    return (
        <div>
            <div className="blog">
                <div className="blog-con">
                    <div className="blog-con-div">
                        <div className="bcd">
                            <div className="bcd-header">
                                Free Digital Marketing Insights
                            </div>
                            <div className="bcd-cards" data-aos='fade-left'>
                                {blogList()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Blog