import React from 'react';
import './Footer.css';
import Logo from '../../img/MFLogo.png';
import { BsTwitterX } from "react-icons/bs";
import { SiInstagram } from "react-icons/si";
import { FaFacebookF } from "react-icons/fa";
import { BsWhatsapp } from "react-icons/bs";
import { FaLinkedinIn } from "react-icons/fa6";
import { FaYoutube } from "react-icons/fa6";
import { Link } from 'react-router-dom';


const Footer = () => {
  return (
    <div>
        <div className="footer">
            <div className="footer-con">
                <div className="footer-con-div">
                    <div className="fcd">
                        <div className="fcd-left">
                            <div className="fcd-left-l">
                                <div className="fcdll-header">
                                    <img src={Logo} alt="" className="fcdll-img" />
                                </div>
                                <div className="fcdll-body">
                                    Improve your branding and marketing stragtegies with our digital marketing
                                    solutions. Driven by accurate industry data, our solutions optimizes 
                                    marketing budget and foster general business growth. 
                                </div>
                                <div className="f-socials">
                                    <div className="f-socials-h">Follow Our Socials</div>
                                    <div className="f-socials-b">
                                        <ul>
                                            <a href='http://www.facebook.com/mediafuseagency'><li><FaFacebookF /></li></a>
                                            <a href='https://twitter.com/mediafuseagency'><li><BsTwitterX /></li></a>
                                            <a href='https://instagram.com/mediafuseagency?igshid=YmMyMTA2M2Y='><li><SiInstagram /></li></a>
                                            <a href='https://www.linkedin.com/company/mediafuse-agency/'><li><FaLinkedinIn /></li></a>
                                            <a href='https://wa.me/message/YMN27FIOKTKDB1'><li><BsWhatsapp /></li></a>
                                            <a href='https://www.youtube.com/@MediaFuseAgency'><li><FaYoutube /></li></a>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="fcd-left-r">
                                <div className="fcdlr-left">
                                    <div className="fcdlr-left-header">Services</div>
                                    <div className="fcdlr-left-body">
                                        <Link to='/services/ad-creatives-and-designs' ><div className="link-sm">Ads Creatives</div></Link>
                                        <Link to='/services/amazon-ads-management' ><div className="link-sm">Amazon Ads Management</div></Link>
                                        <Link to='/services/lead-generation' ><div className="link-sm">Leads Generation</div></Link>
                                        <Link to='/services/social-media-marketing' ><div className="link-sm">Social Media Marketing</div></Link>
                                        <Link to='/services/email-marketing' ><div className="link-sm">Email Marketing</div></Link>
                                        <Link to='/services/affiliate-marketing' ><div className="link-sm">Affiliate Marketing</div></Link>
                                    </div>
                                    <Link to='/services' >
                                        <div className="footer-services button-w-sm">
                                            <span className='footer-services-txt'>See More</span>
                                            <span class="material-symbols-outlined fs-arrow">
                                            arrow_right_alt
                                            </span>
                                        </div>
                                    </Link>
                                </div>
                                <div className="fcdlr-right">
                                <div className="fcdlr-left">
                                    <div className="fcdlr-left-header">Links</div>
                                    <div className="fcdlr-left-body">
                                        <Link to='/about'><div className="link-sm">About Us</div></Link>
                                        <a href='/#reviews'><div className="link-sm">Reviews</div></a>
                                        <Link to='/privacy'><div className="link-sm">Privacy</div></Link>
                                        <Link to='/terms'><div className="link-sm">Terms</div></Link>
                                        <Link to='contact/sales'><div className="link-sm">Contact Us</div></Link>
                                        <Link to='/contact'><div className="link-sm">Support</div></Link>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                        <div className="fcd-right">
                            <div className="f-nwsl">
                                <div className="f-nwsl-h">Newsletter</div>
                                <div className="f-nwsl-hh">Increase your chance of success by 96% when you subscribe to our newsletter!</div>
                                <div className="f-nwsl-f">
                                    <form className='nwsl'>
                                        <div className="f-name">
                                            <label className='f-txt'>Name</label>
                                            <input type="text" name="sub-name" required />
                                        </div>
                                        <div className="f-name">
                                            <label className='f-txt'>Email</label>
                                            <input type="email" name="sub-email" required />
                                        </div>
                                        <input type="submit" className="submit" value="Subscribe"/>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="copyright">
                        <div className="copy">© 2024 MediaFuse Agency. All Rights Reserved.</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Footer