import React from 'react'
import './ServicesTop.css'
import Image from '../../../img/serv-img2.jpg'
import { Link } from 'react-router-dom'

const ServicesTop = () => {
  return (
    <div>
        <div className="services-top">
            <div className="services-top-con">
                <div className="services-top-con-div">
                    <div className="stcd">
                        <div className="stcd-left">
                            <img className="stcd-img" src={Image} alt='MediaFuse Agency Services'/>
                        </div>
                        <div className="stcd-right">
                            <h1 className="stcd-header">
                                Super-Charge Your Brand's Marketing Strategy With High Yield 
                                Digital Marketing Solutions
                            </h1>
                            <p>
                                Successful businesses are channelling about 72% of their marketing budget to digital
                                marketing channels. How does your business compare to that?
                            </p>
                            <p>
                                It's not just for the fun of it. At least 55% of marketing is now digital as more buyers 
                                choose to support their purchase choices with online information. 93% of buyers
                                read online reviews before completing their purchases. (If you add me and my boss, 
                                the number is even more.)
                            </p>
                            <p>
                                Our data-driven digital marketing solutions are designed to generate high yield at minimum costs.
                                They combine years of experience with current industry data to generate more leads, expand brand 
                                awareness and level up marketing impact.
                            </p>
                            <Link to='/contact/sales'>
                                <div className="gl-click-sm button-b">
                                    <span className='gl-click-txt-sm'>Let's get started</span>
                                    <span class="material-symbols-outlined in-left-arrow">
                                        arrow_right_alt
                                    </span>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default ServicesTop