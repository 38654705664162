import React from 'react'
import { Link } from 'react-router-dom'
import About from '../About/About'
import Blog from '../Blog/Blog'
import Convince from '../Convince/Convince'
import Proposal from '../Proposal/Proposal'
import Reviews from '../Reviews/Reviews'
import Service from '../Services/Service'
import { Helmet } from 'react-helmet-async'
import './Intro.css'

const Intro = () => {
  return (
    <>
    <Helmet>
    <meta name="fragment" content="!" />
      <title>
        Expand Your Business - MediaFuse Agency
      </title>
      <meta
        name="description"
        content="Level up your marketing with one of the best digital marketing agencies"
      />
      <link rel="canonical" href="https://mediafuseagency.com" />
      <meta name="mylead-verification" content="4c56da68989747b38486e37322495c90"></meta>
      <meta property="og:title" content="Expand Your Business - MediaFuse Agency" />
      <meta 
          property="og:description"
          content="Level up your marketing with one of the best digital marketing agencies"
      />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://mediafuseagency.com" />
      <meta property="og:site_name" content="MediaFuse Agency" />

      <meta property="og:image" content="https://mediafuseagency.com/static/media/MFP.d57d3e5c53694bbedd86.jpg" />
      <meta property="og:image:secure_url" content="https://mediafuseagency.com/static/media/MFP.d57d3e5c53694bbedd86.jpg" />
      <meta property="og:image:type" content="image/jpg" />
      <meta property="og:image:width" content="1200"/>
      <meta property="og:image:height" content="630"/>
      <meta property="fb:app_id" content="654497786394804" />

      <meta name="twitter:title" content="Expand Your Business - MediaFuse Agency" />
      <meta 
          name="twitter:description" 
          content="Level up your marketing with one of the best digital marketing agencies"
      />
      <meta name="twitter:image" content="https://mediafuseagency.com/static/media/MFP.d57d3e5c53694bbedd86.jpg" />
      <meta name="twitter:card" content="summary_large_image" />
    </Helmet>
    <div className="div">
      <div className="intro App">
        <div className="in-left">
          <div className="in-left-con">
            <div className="intro-header">
            The Last Digital Marketing Agency You Will Ever Need 
              {/* Increase brand reach level up marketing impact */}
            </div>
            <div className="intro-body">
              {/* With our cutting edge digital marketing solutions, 
              you're one click away from generating sales with low CAC */}
              Get more sales with revolutionary cost saving strategies
            </div>
          </div>
          <Link to='/services'>
            <div className="in-left-click button-w">
              <span className='in-left-click-txt'>Let's Get Started</span>
              <span class="material-symbols-outlined in-left-arrow">
                arrow_right_alt
              </span>
            </div>
          </Link>
        </div>
        {/* <div className="in-right">
          <div className="intro-img">
            {/* <img className='img' src={Marketing} alt=''/> */}
          {/* </div> */}
        {/* </div>  */}
      </div>
      <Proposal />
      <Convince />
      <Reviews />
      <Service />
      <About />
      <Blog />
    </div>
    </>
  )
}

export default Intro