import React, { useEffect } from 'react';
import  AOS  from 'aos';
import 'aos/dist/aos.css';
import '../../App.css';
import './Convince.css'
import { Link } from 'react-router-dom';

const Convince = () => {
    useEffect(() => {
        AOS.init({duration: 2000});
    }, []);

  return (
    <div>
        <div className="convince-main">
            <div className="convince">
                <div className="convince-con">
                    <div className="convince-div">
                        <div className="convince-div-header">
                            <h1>Market is wherever buyers say it is.</h1>
                            <h2>Scratch that! Our strategies <span>connect</span> you to them</h2>
                        </div>
                        <div className="convince-cards" data-aos='zoom-in-down'>
                            <div className="con-card">
                                <div className="con-card-text">
                                    <div className='cct'>
                                        <h3>High SERP ranking and tons of free traffic</h3>
                                        <div className="con-card-text-body">
                                            Generating traffic is the primary challenge of 61% of marketers, nevermind free traffic.
                                            Sadly, organic traffic bests other sources in marketing ROI and that explains why big, medium 
                                            and small sized companies each spend thousands of dollars monthly on SEO.
                                        </div>
                                        <ul className="con-card-text-body">
                                            <li>Plug into SEO and increase you ROI</li>
                                            <li>Get tons of free traffic by optimizing your content for SERP</li>
                                            <li>Be among the 10% of websites that generate organic traffic</li>
                                        </ul>
                                    </div>

                                    <hr />
                                    <Link to='contact/sales'>
                                        <div className="glc-click button-b">
                                            <span className='gl-click-txt'>Get started with SEO</span>
                                            <span class="material-symbols-outlined in-left-arrow">
                                                arrow_right_alt
                                            </span>
                                        </div>
                                    </Link>
                                </div>
                            </div>

                            <div className="con-card">
                                <div className="con-card-text">
                                    <div className='cct'>
                                        <h3>Get leads with low cost per acquisition </h3>
                                        <div className="con-card-text-body">
                                            With ROI of 3600%, email marketing remains the holy grail for leads generation. 
                                            It can also enhance your brand image, together with promotions and affiliates. A 
                                            leads generation project comprising these three results in superb branding.
                                        </div>
                                        <ul className="con-card-text-body">
                                            <li>Enjoy 36x on your marketing spends with engaging emails</li>
                                            <li>Get more leads with promotions and affiliate campaigns</li>
                                            <li>Grow you brand with a superb combination of these three</li>
                                        </ul>
                                    </div>

                                    <hr />
                                    <Link to='contact/sales'>
                                        <div className="glc-click button-b">
                                            <span className='gl-click-txt'>Get started with leads</span>
                                            <span class="material-symbols-outlined in-left-arrow">
                                                arrow_right_alt
                                            </span>
                                        </div>
                                    </Link>
                                </div>
                            </div>

                            <div className="con-card">
                                <div className="con-card-text">
                                    <div className='cct'>
                                        <h3>Get consumers looking up your brand</h3>
                                        <div className="con-card-text-body">
                                            Brand awareness can increase up to 80% when you advertise online. Consumers who see your ads
                                            are 155% more likely to look up your brand and if they see it again, they're 70% probably
                                            going to buy. That doesn't mean you can throw anything in their face.
                                        </div>
                                        <ul className="con-card-text-body">
                                            <li>Increase brand awareness with creative ads</li>
                                            <li>Sell more with converting retargeted ads</li>
                                            <li>Get upto 200% ROI with pay per click advertising</li>
                                        </ul>
                                    </div>

                                    <hr />
                                    <Link to='contact/sales'>
                                        <div className="glc-click button-b">
                                            <span className='gl-click-txt'>Get started with ads</span>
                                            <span class="material-symbols-outlined in-left-arrow">
                                                arrow_right_alt
                                            </span>
                                        </div>
                                    </Link>
                                </div>
                            </div>

                            <div className="con-card">
                                <div className="con-card-text">
                                    <div className='cct'>
                                        <h3>Improve existing strategies with MediaFuse</h3>
                                        <div className="con-card-text-body">
                                            There's about a 72% chance that you already have some strategies in place for
                                            brand development and leads generation. If so, whether you're doing well or not,
                                            we're not judging. At MediaFuse we level up the impact of existing strategies.
                                        </div>
                                        <ul className="con-card-text-body">
                                            <li>Expand existing strategies with MediaFuse solutions </li>
                                            <li>Migrate all or part of your strategies or just consult</li>
                                            <li>Level up marketing impact of exsting strategies</li>
                                        </ul>
                                    </div>

                                    <hr />
                                    <Link to='contact/sales'>
                                        <div className="glc-click button-b">
                                            <span className='gl-click-txt'>Get started with management</span>
                                            <span class="material-symbols-outlined in-left-arrow">
                                                arrow_right_alt
                                            </span>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        
        
    </div>
  )
}

export default Convince