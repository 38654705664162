import React from 'react';
import { Helmet } from 'react-helmet-async';
import './Privacy.css'

const Privacy = () => {
  return (
    <>
    <Helmet>
    <meta name="fragment" content="!" />
    <title>
        Privacy Policy - MediaFuse Agency
      </title>
      <meta
        name="description"
        content="ThOurrivacy Policy outlines how we collect, use, 
                 disclose, and safeguard personal information obtained through our website"
      />
      <link rel="canonical" href="https://mediafuseagency.com/privacy" />
      <meta property="og:title" content="Privacy Policy - MediaFuse Agency" />
      <meta 
          property="og:description"
          content="Our Privacy Policy outlines how we collect, use, 
                 disclose, and safeguard personal information obtained through our website"
      />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://mediafuseagency.com/privacy" />
      <meta property="og:site_name" content="MediaFuse Agency" />

      <meta property="og:image" content="https://mediafuseagency.com/static/media/MFP.d57d3e5c53694bbedd86.jpg" />
      <meta property="og:image:secure_url" content="https://mediafuseagency.com/static/media/MFP.d57d3e5c53694bbedd86.jpg" />
      <meta property="og:image:type" content="image/jpg" />
      <meta property="og:image:width" content="1200"/>
      <meta property="og:image:height" content="630"/>
      <meta property="fb:app_id" content="654497786394804" />

      <meta name="twitter:title" content="Privacy Policy - MediaFuse Agency" />
      <meta 
          name="twitter:description" 
          content="Our Privacy Policy outlines how we collect, use, 
                 disclose, and safeguard personal information obtained through our website"
      />
      <meta name="twitter:image" content="https://mediafuseagency.com/static/media/MFP.d57d3e5c53694bbedd86.jpg" />
      <meta name="twitter:card" content="summary_large_image" />
    </Helmet>
    <div>
        <div className="privacy">
            <div className="privacy-con">
                <div className="privacy-con-div">
                    <div className="pcd">
                        <div className="pcd-header">
                            Privacy Policy For MediaFuse Agency
                            <p className="eff-date">Effective Date: May 19, 2023.</p>
                        </div>
                        <div className="pcd-body">
                            <p className="eff-date">
                            MediaFuse Agency ("MediaFuse," "we," "us," or "our") is committed to protecting the privacy 
                            of our clients and website visitors. This Privacy Policy outlines how we collect, use, 
                            disclose, and safeguard personal information obtained through our website. By accessing or 
                            using our Services, you agree to the terms of this Privacy Policy.
                            </p>
                            <p className='eff-bold'>
                            1.	Information We Collect
                            </p>
                            <p className="eff-date">
                            We may collect personal information, which includes but is not limited to:
                            </p>
                            <ul className='eff-list'>
                                <li>Contact information, such as your name, email address, phone number, and mailing address.</li>
                                <li>Business information, such as your company name and job title.</li>
                                <li>Payment information, such as credit card details or other payment-related information.</li>
                                <li>Usage data, including information about how you interact with our Services, pages visited, 
                                    and referring website addresses.</li>
                                <li>Cookies and similar technologies to enhance your experience and gather information about 
                                    your preferences and activities.</li>
                            </ul>
                            <p className='eff-bold'>
                            2.	How We Use Your Information
                            </p>
                            <p className="eff-date">
                            We may use the personal information we collect for the following purposes:
                            </p>
                            <ul className='eff-list'>
                                <li>To provide and improve our Services, customize content, and deliver a personalized experience.</li>
                                <li>To communicate with you, respond to your inquiries, and provide customer support.</li>
                                <li>To process payments and fulfill your requests for services.</li>
                                <li>Usage data, including information about how you interact with our Services, pages visited, 
                                    and referring website addresses.</li>
                                <li>Cookies and similar technologies to enhance your experience and gather information about 
                                    your preferences and activities.</li>
                                <li>To send you marketing communications, updates, and promotional materials, with your consent
                                     were required by applicable laws.</li>
                                <li>To analyze trends, track usage patterns, and gather statistical information to improve the 
                                    functionality and performance of our Services.</li>
                            </ul>
                            <p className='eff-bold'>
                            3.	Information Sharing and Disclosure 
                            </p>
                            <p className="eff-date">
                            We may share your personal information with:
                            </p>
                            <ul className='eff-list'>
                                <li>Service providers and partners who assist us in delivering our Services and carrying out 
                                    business operations on our behalf. These third-party service providers are contractually 
                                    obligated to protect your information and use it only for the purposes specified by us.</li>
                                <li>Compliance with legal obligations, such as responding to subpoenas, court orders, or legal 
                                    processes, or to establish or exercise our legal rights or defend against legal claims.</li>
                                
                            </ul>
                            <p className='eff-bold'>
                            4.	Data Security 
                            </p>
                            <p className="eff-date">
                            MediaFuse takes reasonable measures to protect the security and confidentiality of your personal 
                            information.
                            </p>
                            <p className='eff-bold'>
                            5.	Your Choices and Rights 
                            </p>
                            <p className="eff-date">
                            You have the right to access, correct, update, or delete your personal information. You may also 
                            withdraw your consent for certain processing activities, object to the processing of your information, 
                            or request the restriction of its processing. To exercise these rights or make any inquiries regarding 
                            your personal information, please contact us using the information provided at the end of this Privacy 
                            Policy.
                            </p>
                            <p className='eff-bold'>
                            6.	Third Party Websites and Services 
                            </p>
                            <p className="eff-date">
                            Our Services may contain links to third-party websites or services that are not controlled or operated 
                            by MediaFuse. We are not responsible for the privacy practices or content of those third-party websites 
                            or services. We encourage you to review the privacy policies of any third-party websites or services 
                            you access.
                            </p>
                            <p className='eff-bold'>
                            7.	Children's Privacy 
                            </p>
                            <p className="eff-date">
                            Our Services are not directed to individuals under the age of 18. We do not knowingly collect personal 
                            information from children. If we become aware that we have collected personal information from a child 
                            without parental consent, we will take steps to remove that information from our systems.
                            </p>
                            <p className='eff-bold'>
                            8.	Changes to this Privacy Policy 
                            </p>
                            <p className="eff-date">
                            We may update this Privacy Policy from time to time. Any changes will be effective upon posting the 
                            revised Privacy Policy on our website. We encourage you to review this Privacy Policy periodically to 
                            stay informed about how we collect, use, and protect your personal information.
                            </p>
                            <p className='eff-bold'>
                            9.	Contact Us 
                            </p>
                            <p className="eff-date">
                            If you have any questions, concerns, or requests regarding this Privacy Policy or the handling of your 
                            personal information, please contact us using the following information:
                            </p>
                            <p className="eff-date">
                            MediaFuse Agency
                            </p>
                            <p className="eff-date">
                            Email: hello@mediafuseagency.Com
                            </p>
                            <p className="eff-date">
                            Phone: +2347047046074
                            </p>
                            <p className="eff-date">
                            We will make reasonable efforts to address your inquiries and resolve any concerns or complaints 
                            regarding the privacy of your personal information.
                            </p>
                            <p className="eff-date">Effective Date: May 19, 2023.</p>
                            <p className="eff-date">
                            By using our Services, you acknowledge that you have read, understood, and agreed to the terms of this 
                            Privacy Policy. If you do not agree with any provision of this Privacy Policy, please refrain from 
                            accessing or using our Services.
                            </p>
                            <p className="eff-date">
                            Note: This Privacy Policy is intended to provide a general overview of our practices regarding the 
                            collection, use, and disclosure of personal information. For specific details about how we handle your 
                            information in relation to a particular service or engagement, we may provide additional privacy notices 
                            or agreements as necessary.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </>
  )
}

export default Privacy