import React from 'react';
import { useEffect } from 'react';
import './Reviews.css';
import  AOS  from 'aos';
import 'aos/dist/aos.css';
import Steve from '../../img/steve-west.jpeg';
import Tony from '../../img/Tony.jpeg';
import Cannon from '../../img/Cannon.jpeg';
import Frank from '../../img/Frank.jpeg';
import Randolph from '../../img/Randolph.jpeg';
import Victor from '../../img/Victor.jpeg';
import Angel from '../../img/Angel.jpeg';

const Reviews = () => {
    useEffect(() => {
        AOS.init({duration: 2000});
    }, []);

  return (
    <div>
        <div className="reviews" id='reviews'>
            <div className="reviews-con" data-aos="fade-down">
                <div className="reviews-div">
                    <div className="reviews-header">
                        
                    </div>
                    <div className="reviews-slider">
                        <div className="reviews-slider-con">
                            <div className="reviews-slide">
                                <div className="rs-left">
                                    <div className="rs-img">
                                        <img src={Steve} alt='MediaFuse is one of the top digital marketing agencies' />
                                    </div>
                                </div>
                                <div className="rs-right">
                                    <div className="rs-header">
                                    <span class="material-symbols-rounded">
                                    format_quote
                                    </span>
                                    I wish I met MediaFuse Agency earlier.
                                    </div>
                                    <div className="rs-body">
                                    I had a memorable experience working with Jo from 
                                    MediaFuse Agency. He was patient and understanding and happy to revisit his work to 
                                    improve the results when necessary. Overall, I liked the end product and will 
                                    happily come back if I need him again. I really wish I met him earlier. 
                                    </div>
                                    <div className="rs-author">
                                    -- Steve West ~ (Fiverr)
                                    <span class="material-symbols-rounded">
                                    format_quote
                                    </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="reviews-slider-con">
                            <div className="reviews-slide">
                                <div className="rs-left">
                                    <div className="rs-img">
                                        <img src={Angel} alt='MediaFuse is one of the top digital marketing agencies' />
                                    </div>
                                </div>
                                <div className="rs-right">
                                    <div className="rs-header">
                                    <span class="material-symbols-rounded">
                                    format_quote
                                    </span>
                                    Easy to work with.
                                    </div>
                                    <div className="rs-body">
                                    Working with MediaFuse was quite easy and overall it was a nice experience. 
                                    They strive to please and also deliver quickly. 
                                    </div>
                                    <div className="rs-author">
                                    -- Angel Domingue Jackson ~ (Fiverr)
                                    <span class="material-symbols-rounded">
                                    format_quote
                                    </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="reviews-slider-con">
                            <div className="reviews-slide">
                                <div className="rs-left">
                                    <div className="rs-img">
                                        <img src={Cannon} alt='MediaFuse is one of the top digital marketing agencies' />
                                    </div>
                                </div>
                                <div className="rs-right">
                                    <div className="rs-header">
                                    <span class="material-symbols-rounded">
                                    format_quote
                                    </span>
                                    They can do exactly what is needed.
                                    </div>
                                    <div className="rs-body">
                                    I have to admit, they don't read minds, they work with your directions.
                                    The more concise you are, the better they'll serve you. What you have to know about
                                    MediaFuse is that they can do exactly what is needed. 
                                    </div>
                                    <div className="rs-author">
                                    -- C. Cannon ~ (Fiverr)
                                    <span class="material-symbols-rounded">
                                    format_quote
                                    </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="reviews-slider-con">
                            <div className="reviews-slide">
                                <div className="rs-left">
                                    <div className="rs-img">
                                        <img src={Frank} alt='MediaFuse is one of the top digital marketing agencies' />
                                    </div>
                                </div>
                                <div className="rs-right">
                                    <div className="rs-header">
                                    <span class="material-symbols-rounded">
                                    format_quote
                                    </span>
                                    Top work delivered.
                                    </div>
                                    <div className="rs-body">
                                    MediaFuse is good at what they do, they have professionals on their team who listen to your demands. 
                                    The quality and speed of the delivery was top notch and communication during the process
                                    was excellent too. I definitely recommend them. 
                                    </div>
                                    <div className="rs-author">
                                    -- Frank Yeboah ~ (HealthyVidi)
                                    <span class="material-symbols-rounded">
                                    format_quote
                                    </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="reviews-slider-con">
                            <div className="reviews-slide">
                                <div className="rs-left">
                                    <div className="rs-img">
                                        <img src={Randolph} alt='MediaFuse is one of the top digital marketing agencies' />
                                    </div>
                                </div>
                                <div className="rs-right">
                                    <div className="rs-header">
                                    <span class="material-symbols-rounded">
                                    format_quote
                                    </span>
                                    I appreciate working with MediaFuse.
                                    </div>
                                    <div className="rs-body">
                                    Overall, the job was done to my satisfaction. I was deeply impressed by the result 
                                    and the execution. The communcation too was excellent. Great job. Thanks to these guys. 
                                    </div>
                                    <div className="rs-author">
                                    -- Randolph ~ (Youtube channel Owner)
                                    <span class="material-symbols-rounded">
                                    format_quote
                                    </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="reviews-slider-con">
                            <div className="reviews-slide">
                                <div className="rs-left">
                                    <div className="rs-img">
                                        <img src={Victor} alt='MediaFuse is one of the top digital marketing agencies' />
                                    </div>
                                </div>
                                <div className="rs-right">
                                    <div className="rs-header">
                                    <span class="material-symbols-rounded">
                                    format_quote
                                    </span>
                                    Flexible and communicative.
                                    </div>
                                    <div className="rs-body">
                                    I worked with Jo from 
                                    MediaFuse Agency. They are flexible and communicative. It was satisfyng to work with 
                                    an agency that could adapt to flexible descriptions and still deliver an excellent work. They really listen to
                                    your demands and are professionals at what they do. 
                                    </div>
                                    <div className="rs-author">
                                    -- Victor Lim ~ (Shopify Store Owner)
                                    <span class="material-symbols-rounded">
                                    format_quote
                                    </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="reviews-slider-con">
                            <div className="reviews-slide">
                                <div className="rs-left">
                                    <div className="rs-img">
                                        <img src={Tony} alt='MediaFuse is one of the top digital marketing agencies' />
                                    </div>
                                </div>
                                <div className="rs-right">
                                    <div className="rs-header">
                                    <span class="material-symbols-rounded">
                                    format_quote
                                    </span>
                                    I recommend MediaFuse.
                                    </div>
                                    <div className="rs-body">
                                    I had a memorable experience working with MediaFuse. The job was tidy and well executed.
                                    I recommend their services.
                                    </div>
                                    <div className="rs-author">
                                    -- Tony Walker ~ (Fiverr)
                                    <span class="material-symbols-rounded">
                                    format_quote
                                    </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="reviews-slider-con">
                            <div className="reviews-slide">
                                <div className="rs-left">
                                    <div className="rs-img">
                                        <img src={Steve} alt='MediaFuse is one of the top digital marketing agencies' />
                                    </div>
                                </div>
                                <div className="rs-right">
                                    <div className="rs-header">
                                    <span class="material-symbols-rounded">
                                    format_quote
                                    </span>
                                    I wish I met MediaFuse Agency earlier.
                                    </div>
                                    <div className="rs-body">
                                    I had a memorable experience working with Jo from 
                                    MediaFuse Agency. He was patient and understanding and happy to revisit his work to 
                                    improve the results when necessary. Overall, I liked the end product and will 
                                    happily come back if I need him again. I really wish I met him earlier. 
                                    </div>
                                    <div className="rs-author">
                                    -- Steve West ~ (Fiverr)
                                    <span class="material-symbols-rounded">
                                    format_quote
                                    </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Reviews