import React, { useRef } from 'react';
import './Support.css';
import emailjs from '@emailjs/browser';
import { Helmet } from 'react-helmet-async';

const Support = () => {

  const form = useRef()

  const sendQuery = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_1rzzy7l', 'template_iomqops', form.current, '8ns9UEo-6LJHtdSiy')
      .then((result) => {
        console.log(result.text);
        window.alert('Your  message has been sent successfully. We will respond via your email shortly.');
        window.location.reload();
      }, (error) => {
        console.log(error.text);
        window.alert(error.text)
      });
  };

  return (
    <>
      <Helmet>
        <meta name="fragment" content="!" />
        <title>
          Support - MediaFuse Agency
        </title>
        <meta
          name="description"
          content="Make complaints, suggestions, or get directons through our support channel"
        />
        <link rel="canonical" href="https://mediafuseagency.com/contact" />
        <meta property="og:title" content="Support - MediaFuse Agency" />
        <meta
          property="og:description"
          content="Make complaints, suggestions, or get directons through our support channel"
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://mediafuseagency.com/contact" />
        <meta property="og:site_name" content="MediaFuse Agency" />

        <meta property="og:image" content="https://mediafuseagency.com/static/media/MFP.d57d3e5c53694bbedd86.jpg" />
        <meta property="og:image:secure_url" content="https://mediafuseagency.com/static/media/MFP.d57d3e5c53694bbedd86.jpg" />
        <meta property="og:image:type" content="image/jpg" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="fb:app_id" content="654497786394804" />

        <meta name="twitter:title" content="Support - MediaFuse Agency" />
        <meta
          name="twitter:description"
          content="Make complaints, suggestions, or get directons through our support channel"
        />
        <meta name="twitter:image" content="https://mediafuseagency.com/static/media/MFP.d57d3e5c53694bbedd86.jpg" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <div>
        <div className="support">
          <div className="support-con">
            <div className="support-con-div">
              <div className="spcd">
                <div className="spcd-header">
                  If you have any complaints, reach out to us using this form
                </div>
                <div className="su-form-div">
                  <form ref={form} onSubmit={sendQuery} className="su-form">
                    <div className="name-div">
                      <label className="txt">Name:</label>
                      <input type="text" name="username" className='txt-input' required />
                    </div>
                    <div className="name-div">
                      <label className="txt">Email:</label>
                      <input type="email" name="useremail" className='txt-input' required />
                    </div>
                    <div className="name-div">
                      <label className="txt">Message:</label>
                      <textarea name="usermessage" className='txt-input' rows="5" required />
                    </div>
                    <div className='su-submit'>
                      <input type="submit" className='sc-submit' value="Submit" />
                    </div>
                  </form>
                </div>
                <div className="a-socials">
                  <div className="a-socials-h">Follow Our Socials</div>
                  <div className="a-socials-b">
                    <ul>
                      <a href='http://www.facebook.com/mediafuseagency'><li><i class="fa fa-facebook" aria-hidden="true"></i></li></a>
                      <a href='https://twitter.com/mediafuseagency'><li><i class="fa fa-twitter" aria-hidden="true"></i></li></a>
                      <a href='https://instagram.com/mediafuseagency?igshid=YmMyMTA2M2Y='><li><i class="fa fa-instagram" aria-hidden="true"></i></li></a>
                      <a href='https://www.linkedin.com/company/mediafuse-agency/'><li><i class="fa fa-linkedin" aria-hidden="true"></i></li></a>
                      <a href='https://wa.me/message/YMN27FIOKTKDB1'><li><i class="fa fa-whatsapp" aria-hidden="true"></i></li></a>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Support