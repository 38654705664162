import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from "react-router-dom";
import "./App.css"
import { HelmetProvider } from "react-helmet-async";

import Intro from "./components/Intro/Intro";
import Navbar from "./components/Navbar/Navbar";

import Services from "./components/Services/Services";
import Ad from "./components/Services/Ad";
// import Sp from "./components/Services/Sp";
import Seo from "./components/Services/Seo";
import Am from "./components/Services/Am";
import Bc from "./components/Services/Bc";
import Lgp from "./components/Services/Lgp";
import Sem from "./components/Services/Sem";
import Ppc from "./components/Services/Ppc";
import Aam from "./components/Services/Aam";
import Cm from "./components/Services/Cm";
import Smm from "./components/Services/Smm";
import Bd from "./components/Services/Bd";
// import Yg from "./components/Services/Yg";
import Em from "./components/Services/Em";
import Ws from "./components/Services/Ws";
import Sales from "./components/Contact/Sales";
import Privacy from "./components/Privacy/Privacy";
import Terms from "./components/Privacy/Terms";
import AboutUs from "./components/Privacy/AboutUs";
import Support from "./components/Contact/Support";
import Gwa from "./components/Services/Gwa";


const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Navbar />} >
      <Route index element={<Intro />} />
      <Route path="services" element={<Services />} />
      <Route path="/services/ad-creatives-and-designs" element={<Ad />} />
      {/* <Route path="/services/spotify-promotion" element={<Sp />} /> */}
      <Route path="/services/social-media-marketing" element={<Smm />} />
      <Route path="/services/search-engine-optimization" element={<Seo />} />
      <Route path="/services/affiliate-marketing" element={<Am />} />
      <Route path="/services/business-consulting" element={<Bc />} />
      <Route path="/services/lead-generation" element={<Lgp />} />
      <Route path="/services/search-engine-marketing" element={<Sem />} />
      <Route path="/services/pay-per-click" element={<Ppc />} />
      <Route path="/services/amazon-ads-management" element={<Aam />} />
      <Route path="/services/content-marketing" element={<Cm />} />
      <Route path="/services/brand-development" element={<Bd />} />
      <Route path="/services/grants-writing-and-application" element={<Gwa />} />
      {/* <Route path="/services/youtube-growth" element={<Yg />} /> */}
      <Route path="/services/email-marketing" element={<Em />} />
      <Route path="/services/web-services" element={<Ws />} />
      <Route path="/contact/sales" element={<Sales />} />
      <Route path="/privacy" element={<Privacy />} />
      <Route path="/terms" element={<Terms />} />
      <Route path="/about" element={<AboutUs />} />
      <Route path="/contact" element={<Support />} />
    </Route>
  )
)

function App() {
  return (
    <HelmetProvider>
      <RouterProvider router={router} />
    </HelmetProvider>
  );
}

export default App;
