import React from 'react';
import './About.css';
import { Link } from 'react-router-dom';

const About = () => {
  return (
    <div>
        <div className="about">
            <div className="about-con">
                <div className="about-con-div">
                    <div className="acd">
                        <div className="acd-left">
                            <div className="acd-left-body">
                                Our agency is dedicated to helping businesses grow and succeed in the 
                                digital landscape. Have specific needs to meet your business goals? Need
                                optimized solutions to resolve specific business challenges? Our agency is
                                a good fit for you. We take a customize approach to every project we 
                                undertake and work closely with clients to both understand their specific 
                                needs and to develop tailored strategies that drive results.
                            </div>
                            <Link to='/about'>
                            <div className="gl-click-sm button-b">
                                <span className='gl-click-txt-sm'>About Us</span>
                                <span class="material-symbols-outlined in-left-arrow">
                                    arrow_right_alt
                                </span>
                            </div>
                            </Link>
                        </div>
                        <div className="acd-right">
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default About