import React, { useEffect } from 'react';
import './Proposal.css';
import  AOS  from 'aos';
import 'aos/dist/aos.css';
import '../../App.css';
import '../Intro/Intro.css';
import Client1 from '../../img/client1.png';
import Client2 from '../../img/client2.png';
import Client3 from '../../img/client3.png';
import Client4 from '../../img/client4.png';
import Client6 from '../../img/client6.png';
import Client7 from '../../img/client7.png';
import { Link } from 'react-router-dom';

const Proposal = () => {
    useEffect(() => {
        AOS.init({duration: 2000});
    }, []);

  return (
    <div>
        <div className="clients">
            <div className="clients-con">
                <div className="clients-div" data-aos="zoom-in">
                    <img alt='' src={Client1} />
                    <img alt='' src={Client2} />
                    <img alt='' src={Client3} />
                    <img alt='' src={Client4} />
                    <img alt='' src={Client6} />
                    <img alt='' src={Client7} />
                </div>
            </div>  
        </div>

        <div className="proposal-con">
            
            <div className="work-flow">
                <div className="wf-1">
                    <div className="gl" data-aos="fade-left">
                        <div className="proposal-header" data-aos="fade-up">
                            <h1>Our Data-Driven Solutions Improve Marketing Impact.</h1>
                            <h2>Fuel your Business with high impact marketing</h2>
                        </div>
                        
                        <h3 className="gl-body">Ready to supercharge your brand reach? You can take your brand even further with our optimized digital 
                        marketing powered by SEO, email marketing and laser targetted ads campaigns. 
                        </h3>
                        
                        <Link to='/services'>
                            <div className="gl-click button-b">
                                <span className='gl-click-txt prop-cta'>Let's Get Started</span>
                                <span class="material-symbols-outlined in-left-arrow">
                                    arrow_right_alt
                                </span>
                            </div>
                        </Link>
                    </div>
                    <div className="gl gl-right" data-aos="fade-right">
                    </div>
                </div>
            </div>
        </div>

        <div className="prop-stats">
            <div className="prop-stats-con">
                <div className="prop-stats-div">
                    "Before finalizing a purchase, <br /> <span>94% of B2B buyers</span> <br /> research online"
                </div>
            </div>
        </div>

        
    </div>
  )
}

export default Proposal