import fetch from "isomorphic-fetch";


export const list = () => {
    return fetch('https://blog.mediafuseagency.com/api/blogs/trending', {
        method: 'GET'
    })
        .then(response => {
            console.log(response)
            return response.json();
        })
        .catch(err => console.log(err));
};