import React from 'react';
import { Helmet } from 'react-helmet-async';
import './Privacy.css'

const Terms = () => {
  return (
    <>
    <Helmet>
    <meta name="fragment" content="!" />
    <title>
        Terms of Service - MediaFuse Agency
      </title>
      <meta
        name="description"
        content="By using our Services, you affirm that you are at least 18 years old and capable of entering into a 
                            legally binding agreement"
      />
      <link rel="canonical" href="https://mediafuseagency.com/terms" />
      <meta property="og:title" content="Terms of Service - MediaFuse Agency" />
      <meta 
          property="og:description"
          content="By using our Services, you affirm that you are at least 18 years old and capable of entering into a 
                            legally binding agreement"
      />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://mediafuseagency.com/terms" />
      <meta property="og:site_name" content="MediaFuse Agency" />

      <meta property="og:image" content="https://mediafuseagency.com/static/media/MFP.d57d3e5c53694bbedd86.jpg" />
      <meta property="og:image:secure_url" content="https://mediafuseagency.com/static/media/MFP.d57d3e5c53694bbedd86.jpg" />
      <meta property="og:image:type" content="image/jpg" />
      <meta property="og:image:width" content="1200"/>
      <meta property="og:image:height" content="630"/>
      <meta property="fb:app_id" content="654497786394804" />

      <meta name="twitter:title" content="Terms of Service - MediaFuse Agency" />
      <meta 
          name="twitter:description" 
          content="By using our Services, you affirm that you are at least 18 years old and capable of entering into a 
                            legally binding agreement"
      />
      <meta name="twitter:image" content="https://mediafuseagency.com/static/media/MFP.d57d3e5c53694bbedd86.jpg" />
      <meta name="twitter:card" content="summary_large_image" />
    </Helmet>
    <div>
        <div className="privacy">
            <div className="privacy-con">
                <div className="privacy-con-div">
                    <div className="pcd">
                        <div className="pcd-header">
                            Terms of Use For MediaFuse Agency
                            <p className="eff-date">Effective Date: May 19, 2023.</p>
                        </div>
                        <div className="pcd-body">
                            <p className="eff-date">
                            These Terms of Use ("Agreement") govern your use of the services provided by MediaFuse Agency 
                            ("MediaFuse," "we," "us," or "our"), a digital marketing agency. By accessing or using our website 
                            you agree to be bound by this Agreement. If you do not agree with any part of this Agreement, you 
                            must not use our Services.
                            </p>
                            <p className='eff-bold'>
                            Acceptance of Terms
                            </p>
                            <p className="eff-date">
                            By using our Services, you affirm that you are at least 18 years old and capable of entering into a 
                            legally binding agreement. If you are using our Services on behalf of a company or organization, you 
                            warrant that you have the authority to bind that entity to this Agreement.
                            </p>
                            <p className='eff-bold'>
                            Services Description:
                            </p>
                            <p className="eff-date">
                            MediaFuse provides digital marketing services, including but not limited to search engine optimization, social media marketing, content creation, website development, advertising campaigns, and analytics. The specific details of the services provided to you will be outlined in a separate agreement or proposal.
                            </p>
                            <p className='eff-bold'>
                            Client Responsibilities:
                            </p>
                            <p className="eff-date">
                            As a client, you agree to provide accurate and complete information necessary for us to perform our services effectively.
                            </p>
                            <p className='eff-bold'>
                            Intellectual Property
                            </p>
                            <p className="eff-date">
                            All intellectual property rights, including copyrights, trademarks, and any other proprietary rights, associated with our services, belong to MediaFuse or our licensors. You acknowledge that you have no right, title, or interest in any such intellectual property.
                            </p>
                            <p className='eff-bold'>
                            Confidentiality:
                            </p>
                            <p className="eff-date">
                            Any information shared between MediaFuse and the client that is not publicly available shall be considered confidential. Both parties agree not to disclose or use any confidential information for any purpose other than the fulfillment of the agreed-upon services, unless required by law.
                            </p>
                            <p className='eff-bold'>
                            Payment and Fees:
                            </p>
                            <p className="eff-date">
                            The fees for our services will be outlined in a separate agreement or proposal. Payment terms, including billing cycles and methods of payment, will also be specified. In the event of non-payment, MediaFuse reserves the right to suspend or terminate the provision of services.
                            </p>
                            <p className='eff-bold'>
                            Termination:
                            </p>
                            <p className="eff-date">
                            Either party may terminate this Agreement at any time for any reason by providing written notice to the other party. Upon termination, any outstanding fees and obligations remain due and payable, and any rights granted under this Agreement will cease immediately.
                            </p>
                            <p className='eff-bold'>
                            Limitation of Liability:
                            </p>
                            <p className="eff-date">
                            To the fullest extent permitted by law, MediaFuse shall not be liable for any indirect, incidental, special, or consequential damages arising out of or in connection with the use or inability to use our Services. We make no guarantees regarding the results of our services and disclaim any warranties, whether express or implied.
                            </p>
                            <p className='eff-bold'>
                            Indemnification:
                            </p>
                            <p className="eff-date">
                            You agree to indemnify, defend, and hold MediaFuse, its affiliates, officers, directors, employees, and agents harmless from any claims, liabilities, damages, losses, or expenses, including reasonable attorneys' fees, arising out of your use of our Services or any violation of this Agreement.
                            </p>
                            <p className='eff-bold'>
                            Modifications:
                            </p>
                            <p className="eff-date">
                            MediaFuse reserves the right to modify or update this Agreement at any time without prior notice. Any changes will be effective upon posting the updated Agreement on our website or notifying you via email. It is your responsibility to review this Agreement periodically to stay informed about any modifications.
                            </p>
                            <p className='eff-bold'>
                            Severability:
                            </p>
                            <p className="eff-date">
                            If any provision of this Agreement is found to be invalid, illegal, or unenforceable, the remaining provisions shall continue in full force and effect. The invalid or unenforceable provision shall be deemed superseded by a valid, enforceable provision that most closely matches the intent of the original provision.
                            </p>
                            <p className='eff-bold'>
                            Waiver:
                            </p>
                            <p className="eff-date">
                            The failure of MediaFuse to enforce any right or provision of this Agreement shall not constitute a waiver of such right or provision unless acknowledged and agreed to in writing. Any waiver of a breach of this Agreement shall not constitute a waiver of any subsequent breach or default.
                            </p>
                            <p className="eff-date">
                            By using our Services, you acknowledge that you have read, understood, and agreed to be bound by these Terms of Use.
                            </p>
                            <p className="eff-date">
                            If you have any questions or concerns regarding these Terms of Use or our Services, please contact us at:
                            </p>
                            <p className="eff-date">
                            MediaFuse Agency
                            </p>
                            <p className="eff-date">
                            Email: hello@mediafuseagency.Com
                            </p>
                            <p className="eff-date">
                            Phone: +2347047046074
                            </p>
                            <p className="eff-date">Effective Date: May 19, 2023.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </>
  )
}

export default Terms;